import {React, useState} from 'react';
import {NavLink} from 'react-router-dom'

// Import css
import "../styles/home.css";
import "../styles/header.css";

// Import components
import HeaderMobile from "../components/header-mobile";
import MenuHome from '../components/menu-home';

// Import img
import homeBackground from "../assets/bank-full.jpg";
import logo from "../assets/DK_Logo_Final_Transparent-1280x1024.png";

function Home(){
    const [open, setOpen] = useState(false);
    return(
        <div>
            <div className='desktop-hide'>
                <HeaderMobile open={open} setOpen={setOpen}/>
                <MenuHome open={open} setOpen={setOpen}/>
            </div>
            <div className='mobile-hide'>
                <div className='nav-pos-home navbar'>
                    <NavLink to="/portfolio" className="nav-text-home">portfolio</NavLink>
                    <NavLink to="/philosophy" className="nav-text-home">philosophy</NavLink>
                    <NavLink to="/contact" className="nav-text-home">contact</NavLink>
                </div>
                <NavLink to="/"><img id="logo-home" src={logo}/></NavLink>
            </div>
            <div className='home-title'>conscious luxury</div>
            <img id="bg" src={homeBackground} />
        </div>
    )
}
export default Home; 