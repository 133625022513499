import React from 'react';
import {NavLink} from 'react-router-dom';
import { bool } from 'prop-types';

import "../styles/header.css";

const MenuHome = ({open, setOpen}) => {
    return(
        <div className={open ? 'menu-home-container active' : 'menu-container'}>
            <div className="menu-grid">
                <NavLink to="/portfolio" className="nav-text-home" onClick={() => setOpen(!open)}>portfolio</NavLink>
                <NavLink to="/philosophy" className="nav-text-home" onClick={() => setOpen(!open)}>philosophy</NavLink>
                <NavLink to="/contact" className="nav-text-home" onClick={() => setOpen(!open)}>contact</NavLink>
            </div>
        </div>
    )
}
MenuHome.propTypes = {
    open: bool.isRequired,
}
export default MenuHome;