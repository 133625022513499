import React from 'react';
import { bool } from 'prop-types';

//Import styles
import "../styles/header.css";

function hamburgerClose({open, setOpen}){
    return(
        <>
            <div className={open ? 'hamburger-close' : 'hamburger-close-none'} onClick={() => setOpen(!open)}>
                <div className='square'/>
                <div className='square'/>
                <div className='square'/>
            </div>
        </>
    )
}
hamburgerClose.protoTypes = {
    open: bool.isRequired,
};
export default hamburgerClose