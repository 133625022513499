import {React, useState} from 'react';
import {NavLink} from 'react-router-dom'

// Import css
import "../styles/header.css";
import "../styles/portfolio.css";
import "../styles/philosophy.css";

// Import img
import logo from "../assets/DK_Logo_Final_Transparent-1280x1024.png";

// Components
import HeadMobile from "../components/header-mobile";
import Menu from '../components/menu';

function Philosophy(){
    const [open, setOpen] = useState(false);
    return(
        <div>
            <div className='phil-back'>
                <div className='header header-grid mobile-hide'>
                    <div className='nav-pos-normal navbar'>
                        <NavLink to="/portfolio" className="nav-text">portfolio</NavLink>
                        <NavLink to="/philosophy" className="nav-text">philosophy</NavLink>
                        <NavLink to="/contact" className="nav-text">contact</NavLink>
                    </div>
                    <NavLink to="/" className="logo-grid"><img id="logo" alt="logo" src={logo}/></NavLink>
                </div>
                <div className='header desktop-hide'>
                    <HeadMobile open={open} setOpen={setOpen}/>
                    <Menu open={open} setOpen={setOpen}/>
                </div>
                <div className='phil-body'>
                    <div className='phil-text-cont'>
                        <div className='port-unit-body'>doris<b>krista</b></div>
                        <div className='phil-grid'>
                            <div className='port-unit-body phil-p1'>works as a designer of aesthetic, minimalistic products. Her focus lies on the sustainability of her products, creating serene yet silently powerful pieces, which present a rare luxury.</div>
                            <div className='port-unit-body phil-p2'>Love to detail.<br/>Mindful production.<br/>Peaceful simplicity.<br/>Bliss.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Philosophy;