import logo from './logo.svg';
import './App.css';

import {React, useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Home from "./pages/home"
import Portfolio from "./pages/portfolio";
import Philosophy from "./pages/philosophy";
import Contact from "./pages/contact";

function App() {
  return (
    <div className="App">
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/rkt8kpn.css"/>
      </Helmet>
      <Router>
        <Routes>
          <Route exact path= "/" element={<Home/>}/>
          <Route path= "/portfolio" element={<Portfolio/>}/>
          <Route path= "/philosophy" element={<Philosophy/>}/>
          <Route path= "/contact" element={<Contact/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
