import React from 'react';
import { bool, func } from 'prop-types';
import {NavLink} from 'react-router-dom'

//Import Components

import Hamburger from "./hamburger-open";
import Close from "./hamburger-close";

//Import styles
import "../styles/header.css";

// Import img
import logo from "../assets/DK_Logo_Final_Transparent-1280x1024.png";

function headerMobile({open, setOpen}){
    return(
        <>
            <div className="mobile-header-grid">
                <NavLink to="/" className="logo-grid"><img id="logo" src={logo}/></NavLink>
                <Hamburger open={open} setOpen={setOpen}/>
                <Close open={open} setOpen={setOpen}/>
            </div>
        </>
    )
}
headerMobile.protoTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
};
export default headerMobile;
