import {React, useState} from 'react';
import {NavLink} from 'react-router-dom'

// Import css
import "../styles/header.css";
import "../styles/portfolio.css";

// Import img
import logo from "../assets/DK_Logo_Final_Transparent-1280x1024.png";
import Cube from "../assets/L1260704.jpg";
import FullBench from "../assets/bank-full.jpg";
import SideBench from "../assets/L1680930-2-1.jpg";
import TopBench from "../assets/L1690019-Elise A4.jpg";
import TopBenchClose from "../assets/L1690019-Elise A4-2.jpg";

// Components
import HeadMobile from "../components/header-mobile";
import Menu from '../components/menu';

function Portfolio(){
    const [open, setOpen] = useState(false);
    return(
        <div className='back-page'>
            <div className='header header-grid mobile-hide'>
                <div className='nav-pos-normal navbar'>
                    <NavLink to="/portfolio" className="nav-text">portfolio</NavLink>
                    <NavLink to="/philosophy" className="nav-text">philosophy</NavLink>
                    <NavLink to="/contact" className="nav-text">contact</NavLink>
                </div>
                <NavLink to="/" className="logo-grid"><img id="logo" alt="logo" src={logo}/></NavLink>
            </div>
            <div className='header desktop-hide'>
                <HeadMobile open={open} setOpen={setOpen}/>
                <Menu open={open} setOpen={setOpen}/>
            </div>
            <div className='page-body'>
                <div className='portfolio-unit'>
                    <div className='port-unit-text'>
                        <div className='port-unit-head'>cube</div>
                        <div className='port-unit-body'>massive natural oak <br/>height 45cm <br/>width 45cm <br/>depth 45cm </div>
                    </div>
                    <img className="port-img" src={Cube}/>
                </div>
                <div className='portfolio-unit'>
                    <div className='port-unit-text'>
                        <div className='port-unit-head'>bench º1</div>
                        <div className='port-unit-body'>massive natural oak <br/>height 45cm <br/>width 200cm<br/>depth 40cm</div>
                    </div>
                    <img className="port-img" src={FullBench}/>
                </div>
                <div className='portfolio-unit'>
                    <div className='port-unit-text'> 
                        <div className='port-unit-head'>detail</div>
                        <div className='port-unit-body'>bench º1</div>
                    </div>
                    <div className='fake-text mobile-hide'>massive natural oak</div>
                    <img className="port-img" alt="side-benche" src={SideBench}/>
                </div>
                <img id="top-bench" src={TopBench}/>
                <div className='portfolio-unit'>
                    <div className='fake-text mobile-hide'>massive natural oak</div>
                    <img className="port-img" alt="top-bench-close" src={TopBenchClose}/>
                </div>
            </div>
        </div>
    )
}
export default Portfolio; 