import React from 'react';
import { bool } from 'prop-types';

//Import styles
import "../styles/header.css";

function hamburger({open, setOpen}){
    return(
        <>
            <div className={open ? 'hamburger-none' : 'hamburger'} onClick={() => setOpen(!open)}>
                <div className='square'/>
                <div className='square'/>
                <div className='square'/>
            </div>
        </>
    )
}
hamburger.protoTypes = {
    open: bool.isRequired,
};
export default hamburger